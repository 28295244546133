@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body,
#root {
  margin: 0px;
  background-color: white !important;
  cursor: default;
}

html, 
body {
  max-width: 100%;
  overflow-x: hidden;
}

iframe:focus {
  outline: none;
}

iframe {
  border: none;
}

body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #333333;
}

::-webkit-scrollbar-thumb {
  background: #ffffff88;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff88;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Search input and bodylight fix */
input, .work-sans {
  font-family: WorkSans-Medium, WorkSans-Regular, sans-serif !important;
  font-size: 16px !important;
}

/* Precise px height rules */
.medium-height {
  height: 48px !important;
}

.large-logo {
  height: 72px;
  width: 72px;
}

/* Custom News Box Styling */
.home-title {
  z-index: 18;
  filter: drop-shadow(6px 6px 20px rgba(255, 255, 255, .8));
  text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 70px #fff, 0 0 80px #fff;
}

.small-article-card {
  width: 244px;
}
.small-article-card .image-wrapper {
  height: 136px;
  width: 244px;
  overflow: hidden;
}
.small-article-card .image-wrapper img {
  width: 244px;
  height: auto;
}
.small-article-card .article-card-title {
  font-size: 16px;
  line-height: 20px;
}

.medium-article-card {
  width: 304px;
}
.medium-article-card .image-wrapper {
  height: 170px;
  width: 304px;
  overflow: hidden;
}
.medium-article-card .image-wrapper img {
  width: 304px;
  height: auto;
}
.medium-article-card .article-card-title {
  font-size: 18px;
  line-height: 24px;
}

.large-article-card {
  width: 366px;
}
.large-article-card .image-wrapper {
  height: 200px;
  width: 366px;
  overflow: hidden;
}
.large-article-card .image-wrapper img {
  width: 366px;
  height: auto;
}
.large-article-card .article-card-title {
  font-size: 24px;
  line-height: 28px;
}

.home-postion {
  position: absolute;
  opacity: 0;
}

.position-0 {
  left: 8%;
  top: 100px;
  z-index: 11;
}

.position-1 {
  left: -8%;
  top: 300px;
  z-index: 10;
}

.position-2 {
  left: 6%;
  top: 500px;
  z-index: 10;
}

.position-3 {
  right: 10%;
  top: 40px;
  z-index: 12;
}

.position-4 {
  right: 11%;
  top: 460px;
  z-index: 12;
}

.position-5 {
  right: -6%;
  top: 200px;
  z-index: 10;
}

/* help form styling */
.helpForm {
  height: 1100px;
  display: block;
}

/* login image specific margin */
.login_signup_image {
  position: relative;
  right: 0;
  top: 27px;
}

.login_signup_image img {
  height: auto;
  max-width: 200%;
  z-index: -10
}

.visible-xs, .visible-sm, .visible-md {
  display: none;
}

.home-start {
  margin-top: 60px;
  z-index: 20;
}

/**  this is for the search dropdown **/
.z-1050 {
  z-index: 40;
}

/**  this is for the search dropdown **/
.z-1000 {
  z-index: 1000;
}

.article-content-styling p, .article-content-styling ul, .article-content-styling ol {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 28px;
}

.article-content-styling li {
  list-style-type: square;
  margin-left: 32px;
  padding-left: 4px;
}
.article-content-styling ol li {
  list-style-type: decimal;
}

.mobile-menu-hamburger {
  position: relative;
  top: 36px;
  max-width: 75px;
}

.easeload {
  opacity: 0;
  -webkit-transition: all 2s ease; 
  -moz-transition: all 2s ease; 
  -ms-transition: all 2s ease; 
  -o-transition: all 2s ease; 
  -webkit-transform: translateY(1%);
}

.easeload1 {
  opacity: 0;
  -webkit-transition: all 1s ease; 
  -moz-transition: all 1s ease; 
  -ms-transition: all 1s ease; 
  -o-transition: all 1s ease; 
  -webkit-transform: translateY(-70%);
}

.easeload2 {
  opacity: 0;
  -webkit-transition: all 1s 0.25s ease; 
  -moz-transition: all 1s 0.25s ease; 
  -ms-transition: all 1s 0.25s ease; 
  -o-transition: all 1s 0.25s ease; 
  -webkit-transform: translateX(-70%);
}

.easeload3 {
  opacity: 0;
  -webkit-transition: all 1s 0.5s ease; 
  -moz-transition: all 1s 0.5s ease; 
  -ms-transition: all 1s 0.5s ease; 
  -o-transition: all 1s 0.5s ease; 
  -webkit-transform: translateY(70%);
}

.easeload4 {
  opacity: 0;
  -webkit-transition: all 1s 0.75s ease; 
  -moz-transition: all 1s 0.75s ease; 
  -ms-transition: all 1s 0.75s ease; 
  -o-transition: all 1s 0.75s ease; 
  -webkit-transform: translateY(-70%);
}

.easeload5 {
  opacity: 0;
  -webkit-transition: all 1s 1s ease; 
  -moz-transition: all 1s 1s ease; 
  -ms-transition: all 1s 1s ease; 
  -o-transition: all 1s 1s ease; 
  -webkit-transform: translateY(70%);
}

.easeload6 {
  opacity: 0;
  -webkit-transition: all 1s 1.25s ease; 
  -moz-transition: all 1s 1.25s ease; 
  -ms-transition: all 1s 1.25s ease; 
  -o-transition: all 1s 1.25s ease; 
  -webkit-transform: translateX(70%);
}

.easeloadSlide {
  opacity: 0;
  -webkit-transition: all 1s ease; 
  -moz-transition: all 1s ease; 
  -ms-transition: all 1s ease; 
  -o-transition: all 1s ease; 
  -webkit-transform: translateY(30%);
}

.easeLoadedImage {
  opacity: 1;
  -webkit-transform: translateY(0%);
}

.zone-gradient {
  background: linear-gradient(180deg, #FFF 0%, #F7F7F7 100%);
}

.dark .zone-gradient {
  background: linear-gradient(180deg, #1e222a 0%, #191d24 100%);
}

.search-background-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -100;
}

@media screen and (max-width: 1000px){
  .home-start {
    margin-top: 400px;
  }
}

@media screen and (max-width: 800px){
  .hidden-md {
    display: none;
  }
  .visible-md {
    display: block;
  }
  .home-start {
    margin-top: 400px;
  }
}

@media screen and (max-width: 700px){
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: block;
  }
  .home-title {
    font-size: 90px !important;
    margin-bottom: 20px;
  }
  .home-start {
    margin-top: 460px;
  }
}

@media screen and (max-width: 576px){ 
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }
  .home-title {
    font-size: 80px !important;
    margin-bottom: 20px;
  }
  .home-start {
    margin-top: 220px;
  }
  .position-0 {
    left: -16%;
    top: 20px;
  }
  .position-1 {
    left: -38%;
    top: 320px;
  }
  .position-2 {
    left: -64%;
    top: 150px;
  }
  .position-3 {
    right: -20%;
    top: 40px;
  }
  .position-4 {
    right: -28%;
    top: 320px;
  }
  .position-5 {
    right: -56%;
    top: 200px;
  }
}

@font-face {
  font-family: 'WorkSans';
  font-weight: 400;
  src: url(./assets/fonts/WorkSans-Regular.ttf);
}

@font-face {
  font-family: 'WorkSans';
  font-weight: 500;
  src: url(./assets/fonts/WorkSans-Medium.ttf);
}

@font-face {
  font-family: 'WorkSans';
  font-weight: 600;
  src: url(./assets/fonts/WorkSans-SemiBold.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 400;
  src: url(./assets/fonts/Chivo-Regular.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 500;
  src: url(./assets/fonts/Chivo-Medium.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 700;
  src: url(./assets/fonts/Chivo-Bold.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 800;
  src: url(./assets/fonts/Chivo-ExtraBold.ttf);
}