@import './animations.css';
@import './custom.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'WorkSans';
  font-weight: 400;
  src: url(./assets/fonts/WorkSans-Regular.ttf);
}

@font-face {
  font-family: 'WorkSans';
  font-weight: 500;
  src: url(./assets/fonts/WorkSans-Medium.ttf);
}

@font-face {
  font-family: 'WorkSans';
  font-weight: 600;
  src: url(./assets/fonts/WorkSans-SemiBold.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 400;
  src: url(./assets/fonts/Chivo-Regular.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 500;
  src: url(./assets/fonts/Chivo-Medium.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 700;
  src: url(./assets/fonts/Chivo-Bold.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 800;
  src: url(./assets/fonts/Chivo-ExtraBold.ttf);
}

@font-face {
  font-family: 'Pragati Narrow';
  font-weight: 700;
  src: url(./assets/fonts/PragatiNarrow-Bold.ttf);
}

@font-face {
  font-family: 'Pragati Narrow';
  font-weight: 400;
  src: url(./assets/fonts/PragatiNarrow-Regular.ttf);
}

.visible-xs {
  display: none;
}

@media screen and (max-width: 576px){ 
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }
}